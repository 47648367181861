<template>
  <div class="members">
    <!--    HEADER    -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">{{ fileName }}</h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>
    <div class="preview-container content">
      <!--   HEADER   -->
      <CRow class="m-0 pb-4">
        <CCol
          col="12"
          class="mt-sm-2 mt-2 d-flex justify-content-md-end align-items-center"
        >
          <CButton
            color=""
            class="mr-2 border-dark zq--custom-button"
            variant="outline"
            id="editor-btn"
            @click="openEditor"
          >
            {{ texts.previewFilePage.editor }}
          </CButton>
          <CDropdown
            class="c-header-nav-items dropdown mr-2 action-button-groups"
            color="dark"
            variant="outline"
            placement="bottom-end"
            title="Actions"
            button-content="Dropdown"
          >
            <template #toggler>
              <CButton
                variant="outline"
                color="dark"
                class="zq--custom-button"
              >
                {{ texts.previewFilePage.actions }} <i class="fa fa-caret-down"></i>
              </CButton>
            </template>
            <CDropdownItem class="p-0">
              <CButton
                pill
                color="dark"
                class="action-custom-button"
                variant="ghost"
                @click="updateFile"
              >
                {{ texts.previewFilePage.edit }}
              </CButton>
            </CDropdownItem>
            <CDropdownItem class="p-0">
              <CButton
                pill
                color="dark"
                class="action-custom-button"
                variant="ghost"
              >
                {{ texts.previewFilePage.download }}
              </CButton>
            </CDropdownItem>
            <CDropdownItem class="p-0">
              <CButton
                pill
                color="dark"
                class="action-custom-button"
                variant="ghost"
              >
                {{ texts.previewFilePage.copyPath }}
              </CButton>
            </CDropdownItem>
            <CDropdownItem class="p-0">
              <CButton
                pill
                color="dark"
                class="action-custom-button"
                variant="ghost"
                @click="deleteModal = true"
              >
                {{ texts.previewFilePage.delete }}
              </CButton>
            </CDropdownItem>
          </CDropdown>
          <CButton :color="buttonColor" class="zq--responsive-button">
            {{ texts.previewFilePage.open }}
          </CButton>
        </CCol>
      </CRow>
      <!--    CONTENT  -->
      <div class="form-content" style="border-top:none;">
        <CRow>
          <CCol col="12">
            <CCard class="zq--wizard-card">
              <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">{{ texts.previewFilePage.overview }}</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="basicIsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="basicIsCollapsed" :duration="400">
                <CCardBody>
                  <!--        ID          -->
                  <ClCardRow :label="texts.previewFilePage.idLabel" :content="formData.id" />
                  <!--         Created         -->
                  <ClCardRow :label="texts.previewFilePage.createdLabel" :content="dateFormate(formData.created)" />
                  <!--    FILE  Name            -->
                  <ClCardRow :label="texts.previewFilePage.nameLabel" :content="formData.name" />
                  <!--          Repository Id               -->
                  <ClCardRow :label="texts.previewFilePage.repoIdLabel" :content="formData.repositoryId" />
                  <!--         PATH         -->
                  <ClCardRow :label="texts.previewFilePage.pathLabel" :content="formData.path" />
                  <!--       URI           -->
                  <ClCardRow :label="texts.previewFilePage.uriLabel" :content="formData.uri" />
                  <!--         Metadata             -->
                  <ClCardRow :label="texts.previewFilePage.metadataLabel">
                    <template #content>
                      <ul class="zq--list">
                        <li v-for="(meta, index) in formData.metadata" :key="index">
                          <span>{{ meta.key }}: </span><span>{{ meta.value }}</span>
                        </li>
                      </ul>
                    </template>
                  </ClCardRow>
                  <!--       SIZE           -->
                  <ClCardRow label="Size" :content="formData.size" />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
      </div>
      <Modal
        :modalShow="deleteModal"
        :messageGeneral="delFileMsg"
        :title="deleteFileTitle"
        @doFunction="deleteFile"
        v-on:toggle-modal="closeModal"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {cloneDeep} from "lodash";
import Modal from '@/shared/UI/Modal'
import ClCardRow from "@/shared/UI/ClCardRow";
import ClCardArrow from "@/shared/UI/ClCardArrow";
import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import {webAssets} from "@/config/descriptions/webAssets"
import {dateFormate} from '@/utils/dateFormate';
import {webAssetsTexts} from "@/config/pageTexts/webAssets.json";

export default {
  name: 'FileOverview',
  components: {
    Modal,
    ClCardRow,
    ClCardArrow,
    IconWithTooltip,
  },
  data() {
    return {
      descriptions: {
        ...webAssets.file
      },
      texts: {
        ...webAssetsTexts
      },
      formData: {
        id: '',
        created: '',
        name: '',
        repositoryId: '',
        path: '',
        uri: '',
        metadata: [{key: '', value: ''}],
        size: ''
      },
      options: [],
      basicIsCollapsed: true,
      filesIsCollapsed: false,
      otherIsCollapsed: false,
      deleteModal: false,
      fileId: '',
      fileName: '',
      repoId: '',
      repoName: '',
      query: {},
      sortBy: [
        {
          queryField: 'created',
          order: 'Desc',
        },
      ],
      page: 1,
      deleteFileTitle: webAssetsTexts.previewFilePage.deleteTitle,
      delFileMsg: webAssetsTexts.previewFilePage.deleteMessage,
    }
  },
  created() {
    this.handleGetFileById(this.$route.params.fileId);

    this.fileId = this.$route.params.fileId;

    this.repoId = this.$route.params.id;
    this.repoName = this.$route.params.name;

    routerBreadcrumbs(this.$router.currentRoute);
  },
  computed: {
    ...mapGetters('files', ['success', 'message', 'files', 'file']),
    ...mapGetters('theme', ['theme']),
    buttonColor() {
      switch (this.theme) {
        case 'main' : {
          return ''
        }
        case 'default': {
          return 'success'
        }
        default: {
          return 'success'
        }
      }
    }
  },
  watch: {
    file() {
      this.formData = cloneDeep(this.file);
      this.fileName = this.formData.name;
    },
    formData: {
      deep: true,
      handler: function (val) {
        this.showCMetaInputs = true
        if (val.fileName) this.nameValidate = true;
        if (val.id) this.idValidate = true;
      },
    },
  },
  methods: {
    ...mapActions('files', ['handleDeleteFile', 'handleGetFileById']),
    deleteFile() {
      this.deleteModal = false;
      this.handleDeleteFile({
        id: [this.fileId],
        queryData: this.query,
        sortBy: this.sortBy,
        skip: (this.page - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
      });
      this.$router.push({
        path: `/cms/preview-repository/${this.repoId}/${this.repoName}`,
      });
    },
    closeModal() {
      this.deleteModal = false;
    },
    updateFile() {
      this.$router.push({
        path: `/cms/preview-repository/${this.repoId}/${this.repoName}/update-file/${this.fileId}/${this.fileName}`,
      });
    },
    openEditor(){
      this.$router.push({
        path: `/cms/preview-repository/${this.repoId}/${this.repoName}/file-overview/${this.fileId}/${this.fileName}/file-editor`,
      });
    },
    dateFormate,
  }
};
</script>
<style lang="scss" scoped>
.members {
  height: 100%;
  .form-content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);
    border-top: 1px solid var(--zq-content-border-color);
    .group {
      background: #2eb85c;
      color: #ffffff;
      border-radius: 4px;
    }
  }
  .custom-dropbox {
    list-style: none;
    .dropdown-menu {
      min-width: 80px;
      width: 80px;
    }
  }
  .action-custom-button {
    width: 100%;
  }
}

.dark-grey {
  color: darkgray;
}

.members {
  height: 100%;
  .content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);
    border-top: 1px solid #d8dbe0;
    .text-ellipsis {
      text-overflow: ellipsis;
      width: 150px;
      max-width: 100%;
    }
    .members-action .c-header-nav-items {
      list-style: none;
    }
    .custom-dropbox {
      .dropdown-menu {
        min-width: 80px;
        width: 80px;
      }
    }
    .action-custom-button {
      width: 100%;
    }
  }
  .custom-checkbox {
    .form-check-input {
      margin-top: -5px;
      margin-left: -15px;
      text-align: center;
    }
  }
  .members-metadata--list {
    padding: 0;
  }
  .members-metadata--item {
    span {
      font-weight: bolder;
    }
  }
  .detail-label {
    @media (min-width: 420px) {
      max-width: 220px;
    }
  }
  .action-button-groups {
    list-style: none !important;
    .dropdown-menu {
      min-width: 92px;
    }
  }
}
</style>
